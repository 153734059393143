// services/LoginService.ts
import axios from 'axios';
import * as CONSTANTS  from "../utils/constants/constants";
import { Credentials } from "../interfaces/user/Credentials";
import { Token } from '../interfaces/user/Token';



export class LoginService {
  static async login(credentials: Credentials): Promise<Token | null> {
    try {
      const response = await axios.post<Token>(CONSTANTS.HOST + "auth/login/", credentials);
      return response.data;
    } catch (err: any) {
      console.error(err);
      return null;
    }
  }

  static logout() {
    // Optionally, invalidate the server-side session or token
    return axios.post(CONSTANTS.HOST + "auth/logout/")
      .then(response => {
        console.log('Logged out on server side.');
      })
      .catch(error => {
        console.error('Error logging out on server side:', error);
      });
  }
}

