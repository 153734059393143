import { User } from "../../interfaces/user/User"
import { Device } from "../../interfaces/Device";
import { deflate } from 'pako';
import base45 from 'base45';


export const getQRCode = (user: User, device: Device, compressed = false) => {
    if (!user?.qr_data) {
        return ""
    }
    const qr_data = JSON.parse(user.qr_data);
    qr_data["device_id"] = device.device_id;
    qr_data["device_model"] = device.device_model;
    qr_data["device_nickname"] = device.nickname;
    qr_data["sno"] = device.device_serialnumber;
    qr_data["org"] = user.organization;
    qr_data["demo_mode"] = device?.demo_mode || false;
    qr_data["neuro"] = user.neuro_parms || false;
    
    delete qr_data["stream"];
    delete qr_data["api"]   
    const jsonStr = JSON.stringify(qr_data);

    if (compressed) {
        const compressed = deflate(jsonStr);
        return base45.encode(compressed); // `compressed` is already Uint8Array        
    }

    return jsonStr
}
