import React from 'react';
import { TreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { Typography, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { TreeNode } from './MonitorGroupManager'; // Adjust the import according to your project structure
import { useTranslation } from "react-i18next";

interface GroupTreeProps {
    data: TreeNode[];
    selectedGroup: string;
    expanded: string[];
    handleNodeToggle: (event: React.SyntheticEvent, nodeIds: string[]) => void;
    handleNodeSelect: (event: React.SyntheticEvent | null, nodeId: string) => void;
    handleAddRegion: () => void;
    handleAddGroup: (regionId: string) => void;
    isAdmin: boolean;
}

const GroupTree: React.FC<GroupTreeProps> = ({
    data,
    selectedGroup,
    expanded,
    handleNodeToggle,
    handleNodeSelect,
    handleAddGroup,
    isAdmin,
}) => {
    const theme = useTheme();
	const { t } = useTranslation();  

    const handleLabelClick = (nodeId: string) => (event: React.MouseEvent) => {
        event.stopPropagation();
        handleNodeSelect(event, nodeId);
    };

    const sortNodes = (nodes: TreeNode[]): TreeNode[] => {
        return nodes.sort((a, b) => a.group_name.toLowerCase().localeCompare(b.group_name.toLowerCase()));
    };

    const renderTree = (nodes: TreeNode[]) => (
        <>
            {sortNodes(nodes).map(node => (
                <TreeItem
                    key={node.group_id}  // Ensure unique key for each TreeItem
                    nodeId={node.group_id}
                    label={
                        <div
                            onClick={handleLabelClick(node.group_id)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                userSelect: 'none',  // Disable text selection
                            }}
                        >
                            <Typography
                                component="span"
                                sx={{
                                    cursor: 'pointer',
                                    color: theme.palette.text.primary,
                                    flexGrow: 1,
                                }}
                            >
                                {node.group_name}
                            </Typography>
                            {node.level === 'region' && isAdmin && (
                                <IconButton
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddGroup(node.group_id);
                                    }}
                                >
                                    <AddIcon fontSize="small" sx={{cursor:'pointer'}} />
                                </IconButton>
                            )}
                        </div>
                    }
                >
                    {node.children && node.children.length > 0 ? renderTree(node.children) : null}
                </TreeItem>
            ))}
        </>
    );


    return (
        <Box sx={{ width: 300, p: 2, userSelect: 'none' }} onClick={(e) => e.stopPropagation()}>  {/* Disable text selection for the entire component */}
            <Typography variant="h6">{t("region_structure")}</Typography>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary, cursor: 'pointer' }} />}
                defaultExpandIcon={<ChevronRightIcon sx={{ color: theme.palette.text.primary, cursor: 'pointer' }} />}
                selected={selectedGroup}
                expanded={expanded}
                onNodeToggle={handleNodeToggle}
            >
                {renderTree(data)}
            </TreeView>

        </Box>
    );
};

export default GroupTree;
