import UserService from "../../services/UserService";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import DeviceService from "../../services/DeviceService";
import { DeviceTable } from "../../pages/Home/DeviceTable";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../../pages/ReRoute/ServerProblem";
import { useState, useEffect, useContext } from "react";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
} from "@mui/material";
import { DeviceMgmtDialog } from "./DeviceMgmtDialog";
import { UserContext } from "../../context/UserContext";
import { Device } from "../../interfaces/Device";
import GenericDropdown from "./GenericDropdown";
import { OrgEntry } from "../UserMgmt/UserManagement";

// Import libraries for PDF and QR code generation
import { jsPDF } from "jspdf";
import { renderToStaticMarkup } from "react-dom/server";
import QRCode from "react-qr-code";
import { getQRCode } from "../../utils/functions/QRData";

// Helper function to convert an SVG string to a high-resolution PNG data URL using canvas.
const convertSvgToPngDataUrl = (
  svgString: string,
  width: number,
  height: number,
  scale: number = 2 // default scale factor of 2 for higher resolution
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const svgData = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgString);
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      // Increase canvas dimensions by the scale factor.
      canvas.width = width * scale;
      canvas.height = height * scale;
      const context = canvas.getContext("2d");
      if (context) {
        // Scale context drawing operations.
        context.scale(scale, scale);
        context.drawImage(image, 0, 0, width, height);
        const pngDataUrl = canvas.toDataURL("image/png");
        resolve(pngDataUrl);
      } else {
        reject(new Error("Canvas context is null"));
      }
    };
    image.onerror = (e) => reject(e);
    image.src = svgData;
  });
};

export const DeviceManagement = () => {
  const { t } = useTranslation();
  const userCtx = useContext(UserContext);
  const [cookies] = useCookies(["access_token"]);
  const token = cookies.access_token;
  const [showDialog, setShowDialog] = useState(false);
  const [deviceList, setDeviceList] = useState<Device[]>([]);
  const [filterText, setFilterText] = useState("");
  const [organizations, setOrganizations] = useState<OrgEntry[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<string>("");
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);

  // State for tracking selected devices by device_id
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);

  // PDF settings (used in the QR dialog)
  const [pdfPageSize, setPdfPageSize] = useState<string>("a4");
  const [codesPerRow, setCodesPerRow] = useState<number>(4);

  // New state for controlling the QR Code Sheet dialog.
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [generating, setGenerating] = useState(false);

  function insertDeviceInSortedArray(devices: Device[], newDevice: Device): Device[] {
    const insertIndex = devices.findIndex((device) => device.device_id > newDevice.device_id);
    if (insertIndex === -1) {
      devices.push(newDevice);
    } else {
      devices.splice(insertIndex, 0, newDevice);
    }
    return devices;
  }

  const handleOrganizationChange = (value: string) => {
    setSelectedOrganization(value);
  };

  const addDevice = (device: Device) => {
    insertDeviceInSortedArray(deviceList, device);
    setDeviceList([...deviceList]);
  };

  const removeDevice = (deviceIdToRemove: string) => {
    const updatedList = deviceList.filter((device) => device.device_id !== deviceIdToRemove);
    setDeviceList(updatedList);
    // Also remove from selected devices if needed.
    setSelectedDeviceIds((prev) => prev.filter((id) => id !== deviceIdToRemove));
  };

  const updateDevice = (updatedDevice: Device) => {
    const deviceIndex = deviceList.findIndex((device) => device.device_id === updatedDevice.device_id);
    if (deviceIndex !== -1) {
      deviceList[deviceIndex] = { ...deviceList[deviceIndex], ...updatedDevice };
      setDeviceList([...deviceList]);
    }
  };

  const sortedOrganizations = [...organizations].sort((a, b) =>
    a.organization_name.toLowerCase().localeCompare(b.organization_name.toLowerCase())
  );

  const organizationOptions = [
    { value: "", label: t("All") },
    ...sortedOrganizations.map((org) => ({
      value: org.organization_id,
      label: org.organization_name,
    })),
  ];

  const { data, loading: deviceLoading, error: deviceError } = useFetch({
    url: DeviceService.getDevices(true),
    access_token: token,
  });

  const { data: alarmProfiles, loading: almLoading, error: almError } = useFetch({
    url: DeviceService.getTypes(),
    access_token: token,
  });

  useEffect(() => {
    let isMounted = true;
    if (data && isMounted) {
      setDeviceList(data);
    }
    return () => {
      isMounted = false;
    };
  }, [data]);

  useEffect(() => {
    if (!userCtx?.user?.role) {
      return;
    }
    if (userCtx?.user?.role?.permission_level > 700) {
      setShowOrgDropdown(true);
    } else {
      setShowOrgDropdown(false);
    }
  }, [userCtx?.user?.role]);

  useEffect(() => {
    let isMounted = true;
    UserService.getMyProfile(token)
      .then((response: any) => {
        if (isMounted) {
          const orgData = response.organizations.map((org: OrgEntry) => ({
            organization_id: org.organization_id,
            organization_name: org.organization_name,
            organization_type: org.organization_type,
          }));
          setOrganizations(orgData);
        }
      })
      .catch((error: any) => {
        console.log("Fetch error response:", error);
      })
      .finally(() => {});
    return () => {
      isMounted = false;
    };
  }, [token]);

  if (almLoading || deviceLoading || !alarmProfiles) {
    return <Loading />;
  }
  if (almError || deviceError) {
    return <ServerProblem />;
  }

  const filteredDeviceList = deviceList.filter((device) => {
    return (
      (selectedOrganization === "" || device.owner_organization === selectedOrganization) &&
      (device.device_id.toLowerCase().includes(filterText.toLowerCase()) ||
        device.case_id?.toLowerCase().includes(filterText.toLowerCase()) ||
        device.device_type?.toLowerCase().includes(filterText.toLowerCase()) ||
        device.device_serialnumber?.toLowerCase().includes(filterText.toLowerCase()) ||
        device.device_model?.toLowerCase().includes(filterText.toLowerCase()) ||
        device.nickname?.toLowerCase().includes(filterText.toLowerCase()))
    );
  });

  // Handler for selection changes from DeviceTable.
  const handleDeviceSelect = (deviceId: string, isSelected: boolean) => {
    setSelectedDeviceIds((prev) => {
      if (isSelected) {
        return [...prev, deviceId];
      } else {
        return prev.filter((id) => id !== deviceId);
      }
    });
  };

  // Function to generate a QR code contact sheet as a PDF.
  // Each cell includes the QR code and the device_id (centered below the code).
  const generateQRCodeSheet = async () => {
    // Create the PDF using the selected page size.
    const pdf = new jsPDF({ format: pdfPageSize });
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10; // mm margin for all sides
    const spacing = 8; // spacing between cells
    const availableWidth = pageWidth - 2 * margin;
    // Compute the QR code size based on how many codes should fit per row.
    const qrCodeSize = (availableWidth - (codesPerRow - 1) * spacing) / codesPerRow;

    // Define gap and font size for the device_id text.
    const textGap = 0; // gap between QR code and text in mm
    const fontSize = qrCodeSize / 4; // font size proportional to QR code size
    const cellHeight = qrCodeSize + textGap + fontSize;

    let x = margin;
    let y = margin;
    let colCount = 0;

    // Filter the list of devices to only those that are selected.
    const selectedDevices = deviceList.filter((device) =>
      selectedDeviceIds.includes(device.device_id)
    );

    for (const device of selectedDevices) {
      if (!userCtx?.user) {
        console.error("User context is not available");
        return;
      }
      // Generate the QR code value and ensure it's a string.
      const qrValue = getQRCode(userCtx.user, device);
      const qrValueString = typeof qrValue === "string" ? qrValue : JSON.stringify(qrValue);
      // Create a QR code element using react-qr-code.
      const qrElement = (
        <QRCode value={qrValueString} size={qrCodeSize} bgColor="white" fgColor="black" />
      );
      // Render the element to static SVG markup.
      const svgString = renderToStaticMarkup(qrElement);
      try {
        // Convert the SVG string to a high-resolution PNG data URL.
        const pngDataUrl = await convertSvgToPngDataUrl(svgString, qrCodeSize, qrCodeSize, 8);
        // Add the QR code image to the PDF.
        pdf.addImage(pngDataUrl, "PNG", x, y, qrCodeSize, qrCodeSize);
        // Set the font size and add the device_id text centered below the QR code.
        pdf.setFontSize(fontSize);
        const textX = x + qrCodeSize / 2; // center horizontally in the cell
        const textY = y + qrCodeSize + textGap + fontSize / 2; // position text below the QR code
        pdf.text(device.device_id, textX, textY, { align: "center" });
      } catch (error) {
        console.error("Error generating QR code for device:", device.device_id, error);
      }
      // Move to the next cell.
      x += qrCodeSize + spacing;
      colCount++;
      if (colCount >= codesPerRow) {
        colCount = 0;
        x = margin;
        y += cellHeight + spacing;
        if (y + cellHeight > pageHeight - margin) {
          pdf.addPage();
          y = margin;
        }
      }
    }
    // Open the generated PDF in a new browser tab.
    window.open(pdf.output("bloburl"), "_blank");
  };

  // Called when the user clicks "GENERATE PDF" in the dialog.
  const handleGeneratePdf = async () => {
    setGenerating(true);
    await generateQRCodeSheet();
    setGenerating(false);
    setOpenQrDialog(false);
  };

  return (
    <Box sx={{ mx: 2, mt: 2 }}>
      <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            label={t("Search")}
            variant="outlined"
            value={filterText}
            InputLabelProps={{ shrink: true }}
            placeholder={t("type_to_filter")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h4" align="center">
            {t("device_management")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          {showOrgDropdown && (
            <GenericDropdown
              options={organizationOptions}
              selectedValue={selectedOrganization}
              onChange={handleOrganizationChange}
              title={t("customer_org")}
              sx={{ minWidth: 200 }}
            />
          )}
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            onClick={() => setOpenQrDialog(true)}
            disabled={selectedDeviceIds.length === 0}
            fullWidth
          >
            {t("qr_code_sheet")}
            </Button>
        </Grid>
      </Grid>

      <DeviceTable
        devicelist={filteredDeviceList}
        alarmProp={alarmProfiles}
        removeDevice={removeDevice}
        updateDevice={updateDevice}
        organizationOptions={organizationOptions}
        selectable={true} // enables checkbox column in DeviceTable
        selectedDeviceIds={selectedDeviceIds}
        onDeviceSelect={handleDeviceSelect}
      />

      <DeviceMgmtDialog
        action={"add"}
        openState={showDialog}
        setOpenState={setShowDialog}
        alarmProp={alarmProfiles}
        deviceAction={addDevice}
      />

      {/* QR Code Sheet Settings Dialog */}
      <Dialog open={openQrDialog} onClose={() => !generating && setOpenQrDialog(false)}>
        <DialogTitle>{ t("qr_code_settings") }</DialogTitle>
        <DialogContent>
          {generating ? (
            <Typography>{ t("generating_qr_code_sheet") }...</Typography>
          ) : (
            <>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">{ t("page_size") }</FormLabel>
                <RadioGroup
                  row
                  value={pdfPageSize}
                  onChange={(e) => setPdfPageSize(e.target.value)}
                >
                  <FormControlLabel value="a4" control={<Radio />} label="A4" />
                  <FormControlLabel value="letter" control={<Radio />} label="US Letter" />
                </RadioGroup>
              </FormControl>
              <Box sx={{ mt: 2 }}>
                <Typography gutterBottom>{ t("codes_per_row") }: {codesPerRow}</Typography>
                <Slider
                  value={codesPerRow}
                  min={1}
                  max={8}
                  step={1}
                  onChange={(e, newValue) => setCodesPerRow(newValue as number)}
                  valueLabelDisplay="auto"
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => !generating && setOpenQrDialog(false)} disabled={generating}>
          { t("cancel") }
          </Button>
          <Button onClick={handleGeneratePdf} disabled={generating}>
          { t("generate_qr_pdf") }
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
