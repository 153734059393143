
import { Grid } from "@mui/material";
import WebRTCPlayer from "./WebRTC/WebRTCPlayer";


interface VideoTabProps {
    stream: string
}

export const VideoTab = ({stream } : VideoTabProps) => {

//"S702627"
    return (
    <Grid>
    <WebRTCPlayer 
            video_server = "https://video.sentioweb.com"
            stream_id = {stream}
            channel_id = {"0"}
            authToken = { "aW5maW5pd2VsbDp4YjI1aWYyMDI0IQ==" }
            
    />

    </Grid>
    )
}
