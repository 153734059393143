import { useContext, useEffect, useState, useRef } from "react";
import { AlarmsContext } from "../context/AlarmsContext";
import { NEWS } from "../interfaces/NEWS";
import { Device } from "../interfaces/Device";
import { PatientVitals } from "../interfaces/vitals/PatientVitals";
import { patientVitalsPlaceholder } from "../utils/data/PatientVitalsData";
import UpdateVitals from "../utils/functions/UpdateVitals";
import * as CONSTANTS from "../utils/constants/constants";
import { UserContext } from "../context/UserContext";
import { PatientInfo } from "../pages/Home/Live/LiveUnit";

interface Props {
  device: Device;
  data?: string;
  setDevices?: (devices: Device[]) => void;
}

const useVitalsSocket = ({ device, data, setDevices }: Props) => {
  const [vitals, setVitals] = useState<PatientVitals>(patientVitalsPlaceholder);
  const [news, setNews] = useState<NEWS | null>(null);
  const alarmsCtx = useContext(AlarmsContext);
  const userCtx = useContext(UserContext);
  const [patientInfo, setPatientInfo] = useState<PatientInfo | null>(null);
  const [interventionId, setInterventionId] = useState<string | null>(null);
  // Store a normalized timestamp (ISO string)
  const [timestamp, setTimestamp] = useState<string | null>(null);
  const [gpsLocation, setGPSlocation] = useState<any>(null);
  const last_datagram_ts = useRef(0);
  const [connectStatus, setConnectStatus] = useState<string>("disconnected");
  const socketConnectStatus = useRef(false);

  // Connectivity check functions now use "isMounted" directly.
  const start_monitor_connectivity_check = (isMounted: boolean) => {
    if (isMounted) {
      setTimeout(() => check_monitor_timeout(isMounted), 3000);
    }
  };

  const check_monitor_timeout = (isMounted: boolean) => {
    const timer = setTimeout(() => check_monitor_timeout(isMounted), 3000);
    const time_now = new Date().getTime();
    const diff = time_now - last_datagram_ts.current;
    if (diff > CONSTANTS.CONNECTIVITY_TIMEOUT) {
      if (socketConnectStatus.current && isMounted) {
        setConnectStatus("disconnected");
        socketConnectStatus.current = false;
      }
    }
    if (!isMounted) {
      clearTimeout(timer);
    }
  };

  const strobe_connectivity = (isMounted: boolean) => {
    if (!socketConnectStatus.current && isMounted) {
      setConnectStatus("connected");
      socketConnectStatus.current = true;
    }
    last_datagram_ts.current = new Date().getTime();
  };

  const sortScores = () => {
    if (setDevices) {
      // @ts-ignore
      setDevices((prevDevices: Device[]) =>
        prevDevices.sort((a, b) =>
          Number(b.noData ? b.noData : true > a.noData! ? a.noData : true) * 2 - 1
        )
      );
    }
  };

  useEffect(() => {
    let isMounted = true;

    // Only create a socket if the user's organization exists.
    if (!userCtx?.user?.organization) return;

    // Delay the connection establishment by 100ms.
    const connectionDelay = 100;
    const timeoutId = setTimeout(() => {
      const socketUrl =
        CONSTANTS.WS_HOST() +
        "ws/vicu-socket/" +
        (userCtx?.user?.organization || "") +
        "/" +
        device.device_id +
        "/";
      console.log("Requesting websocket at", socketUrl);
      const socket = new WebSocket(socketUrl);

      socket.onmessage = (event) => {
        try {
          if (event.data !== "") {
            const parsedData = JSON.parse(event.data);
            const rawTs = parsedData.message.timestamp;
            // Normalize the timestamp using toISOString()
            const normalizedTs = rawTs ? new Date(rawTs).toISOString() : "";
            const intervention_id = parsedData.message.intervention_id;
            if (isMounted) {
              setTimestamp(normalizedTs);
              setInterventionId(intervention_id);
            }
            strobe_connectivity(isMounted);
            sortScores();

            UpdateVitals(event, setVitals, setNews, alarmsCtx?.setAlarms, setPatientInfo);

            const gps = parsedData?.message?.gps_location;
            if (gps && gps.lat && gps.lng) {
              if (!isNaN(gps.lat) && !isNaN(gps.lng)) {
                setGPSlocation(gps);
              }
            }
          } else {
            if (isMounted) sortScores();
          }
        } catch (err: any) {
          console.error(err);
        }
      };

      socket.onopen = () => {
        const streamRequest = {
          type: "server-request",
          opcode: data ?? "stream_parameters",
          organization: userCtx?.user?.organization,
          device_id: device.device_id,
        };
        socket.send(JSON.stringify(streamRequest));
        start_monitor_connectivity_check(isMounted);
      };

      socket.onerror = (event) => {
        console.error("WebSocket error:", event);
      };

      socket.onclose = (event) => {
        console.warn(`WebSocket closed (code: ${event.code}, reason: ${event.reason})`);
      };

      // Cleanup: close the socket if the component unmounts.
      return () => {
        console.log("Closing socket");
        socket.close();
      };
    }, connectionDelay);

    return () => {
      clearTimeout(timeoutId);
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device.device_id, data, userCtx?.user?.organization, setDevices]);

  return {
    vitals,
    news,
    patientInfo,
    gpsLocation,
    connectStatus,
    timestamp,
    setDevices,
    interventionId,
  };
};

export default useVitalsSocket;
