import React, { useState } from 'react';
import { TreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { OrganizationHierarchyNode } from './types';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

interface Props {
    onSelectNode: (nodeId: string) => void;
    nodes: OrganizationHierarchyNode[];
    selected: string;
}

const TreeNavigator: React.FC<Props> = ({ onSelectNode, nodes, selected }) => {
    // Initialize expanded state with the IDs of the top-level nodes
    const [expanded, setExpanded] = useState<string[]>(nodes.map(node => node.id));
    const theme = useTheme();

    // Function to handle clicks that should only trigger on the chevron icon
    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        const target = event.target as HTMLElement;
        const iconClicked = target.closest('.MuiTreeItem-iconContainer');
        if (iconClicked) {
            setExpanded(nodeIds); // Allow the expand/collapse to proceed
        } else {
            // If the click is not from the icon, prevent changing the expanded state
            event.stopPropagation();
        }
    };

    // Function to handle label clicks
    const handleLabelClick = (nodeId: string) => (event: React.MouseEvent) => {
        event.stopPropagation(); // Stop the click from propagating
        onSelectNode(nodeId);
        // Expand the node if it's not already expanded
        if (!expanded.includes(nodeId)) {
            setExpanded(prev => [...prev, nodeId]);
        }
    };

    // Recursively render tree nodes
    const renderTree = (node: OrganizationHierarchyNode) => (
        <TreeItem
            key={node.id}
            nodeId={node.id}
            label={
                <div onClick={handleLabelClick(node.id)} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography
                        component="span"
                        sx={{ 
                            cursor: 'pointer', 
                            color: theme.palette.text.primary,
                            flexGrow: 1
                        }}
                    >
                        {node.description || node.node_name}
                    </Typography>
                </div>
            }
        >
            {node.nodes.map(childNode => renderTree(childNode))}
        </TreeItem>
    );

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary, cursor:'pointer' }}/>}
            defaultExpandIcon={<ChevronRightIcon sx={{ color: theme.palette.text.primary, cursor:'pointer' }}/>}
            selected={selected}
            expanded={expanded}
            onNodeToggle={handleToggle}
        >
            {nodes.map(node => renderTree(node))}
        </TreeView>
    );
};

export default TreeNavigator;
