import React, { useEffect, useRef } from "react";

interface WebRTCPlayerProps {
  video_server: string;
  stream_id: string;
  channel_id: string;
  width?: string | number; // Width can be a number (for pixels) or a string (for percentages, etc.)
  height?: string | number; // Height can be a number (for pixels) or a string (for percentages, etc.)
  authToken: string; // Add in base 64 encoded authentication
}

const WebRTCPlayer: React.FC<WebRTCPlayerProps> = ({
  width,
  height,
  stream_id,
  channel_id,
  video_server,
  authToken,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const src = `${video_server}/stream/${stream_id}/channel/${channel_id}/webrtc?uuid=${stream_id}&channel=${channel_id}`
  
  useEffect(() => {
    const startPlay = async (videoEl: HTMLVideoElement, url: string) => {
      const pc = new RTCPeerConnection({
        iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }],
      });
  
      pc.ontrack = (event) => {
        videoEl.srcObject = event.streams[0];
        videoEl.play();
      };
  
      pc.addTransceiver("video", { direction: "sendrecv" });
  
      pc.onnegotiationneeded = async () => {
        const offer = await pc.createOffer();
        await pc.setLocalDescription(offer);
  
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Basic ${authToken}`, // e.g. "Basic aW5maW5pd2VsbDp4YjI1aWYyMDI0IQ=="
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              data: btoa(pc.localDescription!.sdp),
            }),
          });
          
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          
          const answerBase64 = await response.text();
          pc.setRemoteDescription(
            new RTCSessionDescription({ type: "answer", sdp: atob(answerBase64) })
          );
        } catch (err) {
          console.error("Error negotiating with server:", err);
        }
      };
  
      // Optional data channel, for demonstration
      const webrtcSendChannel = pc.createDataChannel("rtsptowebSendChannel");
      webrtcSendChannel.onopen = () => {
        console.log(`${webrtcSendChannel.label} has opened`);
        webrtcSendChannel.send("ping");
      };
      webrtcSendChannel.onclose = () => {
        console.log(`${webrtcSendChannel.label} has closed`);
        startPlay(videoEl, url);
      };
      webrtcSendChannel.onmessage = (event) => console.log(event.data);
    };
  
    if (videoRef.current) {
      startPlay(videoRef.current, src);
    }
  }, [src, authToken]);
  
  // Prepare the video styles
  const videoStyles = {
    maxWidth: width ? width : "100%", // Use provided width or fallback to 100%
    maxHeight: height ? height : "100%", // Use provided height or fallback to 100%
  };

  return (
    <video
      ref={videoRef}
      autoPlay
      muted
      playsInline
      controls
      style={videoStyles}
    ></video>
  );
};

export default WebRTCPlayer;