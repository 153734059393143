import {
    Grid,
    Typography,
    useTheme,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { OrganizationHierarchyNode } from "./types"; // Define your types in a separate file or within components
import OrgService from "../../services/OrganizationService";

interface Props {
    nodeData?: OrganizationHierarchyNode;
    openState: boolean;
    setOpenState: (show: boolean) => void;
    triggerRefresh: () => void;
    dialogMode: string;
    parentNode: OrganizationHierarchyNode | null;
    childNodeType?: string;
    setNodeData: React.Dispatch<React.SetStateAction<OrganizationHierarchyNode | null>>;
    onNodeDeleted: (node_id: string, parent_id: string) => void;
    disableLicenseQty: boolean;
}

export const NodeEditDialog = ({
    nodeData,
    disableLicenseQty,
    setNodeData,
    onNodeDeleted,
    triggerRefresh,
    parentNode,
    childNodeType,
    openState,
    setOpenState,
    dialogMode,
}: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [cookies] = useCookies(["access_token"]);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [description, setDescription] = useState<string>("");
    const [numLicenses, setNumLicenses] = useState<number>(0);
    const [descriptionChanged, setDescriptionChanged] = useState(false);
    const [licensesChanged, setLicensesChanged] = useState(false);
    const [availableLicenses, setAvailableLicenses] = useState<number>(nodeData?.num_licenses || 0);

    // New state for error alert dialog
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (!nodeData) return;
        setDescription(dialogMode === "edit" ? nodeData.description : "");
        setNumLicenses(dialogMode === "edit" ? nodeData.licensePool : 0);
    }, [nodeData, dialogMode]);

    useEffect(() => {
        if (!parentNode) return;
        const parent_pool = parentNode.licensePool;
        const parent_assigned = parentNode.numAssignedLicenses;
        const available = parent_pool - parent_assigned;
        setAvailableLicenses(available);
    }, [parentNode, nodeData]);

    const handleClose = () => {
        console.log("handleClose called");
        setOpenState(false);
    };

    function generateUniqueID() {
        const date = new Date();
        return "" + date.getTime(); // getTime returns the number of milliseconds since January 1, 1970
    }

    const handleSaveChanges = () => {
        console.log("NodeEditDialog: Handle save changes:", dialogMode, parentNode, childNodeType);
        if (!nodeData) {
            return;
        }

        if (dialogMode === "add") {
            const data = {
                node_name: generateUniqueID(),
                node_type: childNodeType,
                description: description,
                num_licenses: numLicenses,
                parent: parentNode?.node_name,
            };
            OrgService.addHierarchyNode(data, cookies.access_token)
                .then((res: any) => {
                    console.log("addHierarchyNode result:", res);
                    if (res.status !== 200) {
                        console.log("Error adding node:", res);
                        setErrorMessage( t(res.data || "unknown_error"));
                        setErrorAlertOpen(true);
                        return;
                    }
                    setShowSaveButton(false);
                    setDescriptionChanged(false);
                    setLicensesChanged(false);
                    nodeData.description = description;
                    nodeData.licensePool = numLicenses;
                    setNodeData(nodeData);
                })
                .catch((err: any) => {
                    console.log("addHierarchyNode failed:", err);
                    setErrorMessage(
                        t("Error adding node: ") +
                            (err.response?.data?.message || err.message || "Unknown error")
                    );
                    setErrorAlertOpen(true);
                })
                .finally(() => {
                    triggerRefresh();
                    handleClose();
                });
            return;
        } // if "add"

        // We have an EDIT
        const data = {
            node_name: nodeData.node_name,
            description: description,
            num_licenses: numLicenses,
        };
        console.log("EDIT:", data);
        OrgService.editHierarchy(data, cookies.access_token)
            .then((res: any) => {
                if (res.status !== 200) {
                    console.log("Error modifying node:", res);
                    setErrorMessage( t(res.data || "unknown_error"));
                    setErrorAlertOpen(true);
                    return;
                }
            setShowSaveButton(false);
                setDescriptionChanged(false);
                setLicensesChanged(false);
                nodeData.description = description;
                nodeData.licensePool = numLicenses;
                setNodeData(nodeData);
            })
            .catch((err: any) => {
                console.log(err);
            })
            .finally(() => {
                triggerRefresh();
                handleClose();
            });
    };

    useEffect(() => {
        let showSave = false;
        switch (dialogMode) {
            case "edit":
                showSave = descriptionChanged || licensesChanged;
                break;
            case "add":
                showSave = descriptionChanged || licensesChanged;
                break;
        }
        setShowSaveButton(showSave);
    }, [dialogMode, descriptionChanged, licensesChanged, description, numLicenses]);

    const deleteVICU = () => {
        setConfirmDelete(true);
    };

    const cancelDelete = () => {
        setConfirmDelete(false);
    };

    const yesDelete = () => {
        if (!nodeData) return;
        OrgService.deleteHierarchyNode(cookies.access_token, nodeData.id, nodeData.parentName)
            .then((res: any) => {
                setConfirmDelete(false);
                setShowSaveButton(false);
                setDescriptionChanged(false);
                setLicensesChanged(false);
                nodeData.description = description;
                nodeData.licensePool = numLicenses;
                console.log("Deleted: ", nodeData, parentNode);
                onNodeDeleted(nodeData.id, parentNode?.id ?? "");
            })
            .catch((err: any) => {
                console.log(err);
            })
            .finally(() => {
                handleClose();
                triggerRefresh();
            });
    };

    const handleItemChange = (id: string, data: any) => {
        switch (id) {
            case "description": {
                const val: string = String(data);
                setDescriptionChanged(val.trim() !== nodeData?.description);
                setDescription(val);
                break;
            }
            case "numLicenses": {
                const val: number = Number(data);
                setLicensesChanged(val !== nodeData?.licensePool);
                setNumLicenses(val);
                break;
            }
        }
    };

    return (
        <>
            {/* Error Alert Dialog */}
            <Dialog open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
                <DialogTitle>{t("error_saving_node")}</DialogTitle>
                <DialogContent>
                    <Typography>{errorMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrorAlertOpen(false)}>
                        {t("close")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={confirmDelete} onClose={cancelDelete}>
                <DialogTitle>{t("confirm_domain_delete")}</DialogTitle>
                <DialogContent>
                    <Typography>{t("delete_domain_notification")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Button variant="outlined" onClick={cancelDelete}>
                            {t("cancel")}
                        </Button>
                        <Button variant="contained" onClick={yesDelete} color="warning">
                            {t("yes_delete")}
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Dialog open={openState} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Box>{t("domain_administration")}</Box>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container pt={3} mb={1} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label={t("description")}
                                id="description"
                                type="text"
                                value={description}
                                onChange={(e) => handleItemChange("description", e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                placeholder={t("Enter description")}
                                fullWidth
                                sx={{
                                    input: { color: theme.palette.text.primary },
                                    label: { color: theme.palette.text.primary },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    label={t("Total Licenses")}
                                    id="licenses"
                                    value={numLicenses}
                                    type="number"
                                    disabled={disableLicenseQty}
                                    onChange={(e) => handleItemChange("numLicenses", e.target.value)}
                                    sx={{
                                        input: { color: theme.palette.text.primary },
                                        label: { color: theme.palette.text.primary },
                                        width: { md: 100 },
                                    }}
                                    inputProps={{ min: 0 }}
                                />
                                {!disableLicenseQty && (
                                    <Typography
                                        onClick={() => handleItemChange("numLicenses", availableLicenses)}
                                        sx={{
                                            cursor: "pointer",
                                            color: theme.palette.primary.main,
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        }}
                                    >
                                        Max: {availableLicenses}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Button variant="outlined" onClick={handleClose}>
                            {t("close")}
                        </Button>
                        {showSaveButton && (
                            <Button onClick={handleSaveChanges} variant="outlined">
                                {t("save_changes")}
                            </Button>
                        )}
                        <Button variant="outlined" onClick={deleteVICU}>
                            {t("delete")}
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

interface ItemProps {
    title: any;
    content: any;
}

export const CardItem = ({ title, content }: ItemProps) => {
    return (
        <Grid container>
            <Grid item xs={6}>
                <Typography>{title}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography>{content}</Typography>
            </Grid>
        </Grid>
    );
};
