import React, { useEffect, useState } from 'react';
import { Card, CardContent, FormControlLabel, FormGroup, Switch, Typography, FormControl, Button, Box, TextField } from '@mui/material';
import ServerService from '../../services/ServerService';
import { useCookies } from "react-cookie";
import CustomSelect from '../../components/CustomSelect';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

interface SentioVariants {
  EMS: boolean;
  RPM: boolean;
  CMS: boolean;
}

const ServerParameters: React.FC = () => {
  const [cookies] = useCookies(['access_token']);
  const [serverParameters, setServerParameters] = useState<any>(null);
  const [hotstoreDuration, setHotstoreDuration] = useState<number>(0); // New state for hotstorage_duration
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const [sentioType, setSentioType] = useState<string>('');
  const [sentioVariants, setSentioVariants] = useState<SentioVariants>({
    EMS: false,
    RPM: false,
    CMS: false
  });

  const theme = useTheme();

  useEffect(() => {
    let isMounted = true;

    const fetchServerParameters = async () => {
      try {
        const data = await ServerService.getServerParameters(cookies.access_token);
        if (isMounted) {
          //console.log("serverparms:", data);
          setServerParameters(data);
          const { current } = data;
          setSentioType(current.productType);
          setSentioVariants(current.variants as SentioVariants);
          setHotstoreDuration(current.hotstore_duration || 0); // Populate hotstorage_duration
          setLoading(false);
        }
      } catch (error: any) {
        if (isMounted) {
          setError(error.message);
          setLoading(false);
        }
      }
    };

    fetchServerParameters();

    return () => {
      isMounted = false;
    };
  }, [cookies.access_token]);

  const handleSentioTypeChange = (value: string) => {
    setSentioType(value);
  };

  const handleVariantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const enabledVariants = Object.values(sentioVariants).filter(value => value).length;

    if (!checked && enabledVariants === 1) {
      return; // Prevent disabling the last remaining variant
    }

    setSentioVariants(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSave = async () => {
    const payload = {
      sentioType,
      sentioVariants,
      hotstore_duration: hotstoreDuration // Include hotstorage_duration in the payload
    };

    try {
      await ServerService.saveServerParameters(payload, cookies.access_token);
      alert('Settings saved successfully');
    } catch (error) {
      console.error('Error saving settings', error);
      alert('Failed to save settings');
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error loading data: {error}</Typography>;
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" color="textSecondary">
          {t("server_parameters")}
        </Typography>

        <CustomSelect
          label="SentioWeb Type"
          options={Object.entries(serverParameters.defs.sentio_types) as [string, string][]}
          value={sentioType}
          onChange={handleSentioTypeChange}
          valueExtractor={(option: string[]) => option[0]}
          descriptionExtractor={(option: string[]) => option[1]}
        />

        {sentioType !== "pharlap" && (
          <FormControl component="fieldset" margin="normal">
            <FormGroup>
              <Typography variant="subtitle1" color="textSecondary">
                Enable/Disable Variants
              </Typography>

              {Object.entries(serverParameters.defs.sentio_variants).map(([key, value]) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Switch
                      checked={sentioVariants[key as keyof SentioVariants]}
                      onChange={handleVariantChange}
                      name={key}
                      color="primary"
                      sx={{
                        '& .MuiSwitch-track': {
                          backgroundColor: theme.palette.primary.main,
                        },
                        '& .MuiSwitch-thumb': {
                          color: theme.palette.primary.main,
                        }
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        color: sentioVariants[key as keyof SentioVariants]
                          ? theme.palette.text.primary
                          : theme.palette.text.disabled
                      }}
                    >
                      {value as string}
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        )}

        {/* New input for hotstorage_duration */}
        <FormControl margin="normal">
          <TextField
            label="Hotstorage Duration (hours)"
            type="number"
            value={hotstoreDuration}
            onChange={(e) => setHotstoreDuration(Number(e.target.value))}
            fullWidth
          />
        </FormControl>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button variant="outlined" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ServerParameters;
