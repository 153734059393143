import { Grid, Box, useTheme, 	Dialog, Button,
	DialogActions,
	DialogContent,
	DialogTitle,
    Typography,
 } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import useFetch from "../../../hooks/useFetch";
import { Device } from "../../../interfaces/Device";
import DeviceService from "../../../services/DeviceService";
import { patientVitalsColor } from "../../../utils/data/PatientVitalsColor";
import { TrendChart } from "./TrendChart";
import "./TrendUnit.css";
import moment from "moment";
import { ApexOptions } from "apexcharts";
import {SelectParameters} from "../../../components/device/SelectParameters";
//import { UserContext } from "../../../context/UserContext";
import { Loading } from "../../../components/Loading";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@mui/icons-material/Settings";
import useSentioSocket from "../../../hooks/useSentioSocket";


const vitalsColorMap = patientVitalsColor();


interface Props {
    device: Device;
    hideTrend?: boolean;
    type: string;
    useLink: boolean;
    connectStatus?: string;
    fullWidth?: boolean;
    intervention_id?: string;
	showSettings?: boolean;
	showEkgRecording?: boolean;
	showPatientInfo?: boolean;
	showConnectionStatus?: boolean;
	showDeviceName?: boolean;
	showChartIcon?: boolean;
	showDeviceInfo?: boolean;
}

interface TrendData {
    data: {
        [key: string]: string[] | number[] | undefined;
        timestamps?: number[];
    };
    device_def: {
        parameters: {
            [key: string]: any;
        };
    };
}


const ALL_PARAMS = ["HR", "SpO2", "IBP1", "NIBP", "Resp", "Temp", "EtCO2"]; // , "IBP2",

const TrendUnit = ({
    device,
    hideTrend,
    type,
    fullWidth,
    intervention_id,

}: Props) => {
    //console.log ("TrendUnit renders... will fetch data, intervention_id:", intervention_id)

    const [cookies, ,] = useCookies(["access_token"]);
    const { t } = useTranslation();
    var token = cookies.access_token;

    // trendData is the data from the back-end, and it gets updated when new data is received
    const [trendData, setTrendData] = useState<TrendData>();
    const [trendParameters, setTrendParameters] = useState<{[key: string]: any;}>();

    // serieis is the data that is displayed on the chart. It is converted from trendData
    const [series, setSeries] = useState<ApexAxisChartSeries>([]);
    const [displaySeries, setDisplaySeries] = useState<ApexAxisChartSeries>([]);
    const [displayColors, setDisplayColors] = useState<string[]>([]);
    const [minTime, setMinTime] = useState<number>(0);
    const [maxTime, setMaxTime] = useState<number>(0);
    const [open, setOpen] = useState(false);
	const [dataPacket, setDataPacket] = useState<any>(null);

    	// Set up streaming of live vital signs
	useSentioSocket({ device, setDataPacket, mode: "stream_trend" });


    
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = (
		event: React.SyntheticEvent<unknown>,
		reason?: string
	) => {
		if (reason !== "backdropClick") {
			setOpen(false);
		}
	};

    const mapTrendDataToSeries = (
        trendData: TrendData["data"], 
        trendParameters: string[], 
        timestamps: number[] | undefined
    ) => {
        if (!timestamps || timestamps.length === 0)
            return { mappedSeries: [], minTime: 0, maxTime: 0 };
            
        // Convert timestamps to local time
        const adjustedTimestamps = timestamps.map((timestamp) => moment.utc(timestamp * 1000).local().valueOf());
    
        // Find min and max time
        const minTime = Math.min(...adjustedTimestamps);
        const maxTime = Math.max(...adjustedTimestamps);
    
        const mappedSeries = trendParameters.map((param) => {
            // Map parameters
            let useParam = param;
            switch (param) {
                case "NIBP":
                    useParam = "NIBP_Systole";
                    break;
                case "IBP1":
                    useParam = "IBP1_Systole";
                    break;
                case "Temp":
                    useParam = "Temp1";
                    break;
            }
    
            const paramData = trendData[useParam];
            const seriesData = paramData
                ? paramData.map((value: string | number, index: number) => ({
                      x: new Date(adjustedTimestamps[index]),
                      y: value === "-" || value === "0" ? null : parseFloat(value.toString()),
                  }))
                : adjustedTimestamps.map((timestamp) => ({
                      x: new Date(timestamp),
                      y: null,
                  }));
    
            return {
                name: param,
                data: seriesData,
            };
        });
    
        //console.log ("Returning mappedSeries:", mappedSeries)
        return { mappedSeries, minTime, maxTime };
    };
    
    
    
    

    useEffect(() => {
       let isMounted = true;
       //console.log ("refresh intervention id:", intervention_id)
       const  d = {
            device_id: device?.device_id,
            intervention_id: intervention_id,
            data_type: "trend",
        };

        DeviceService.getInterventionData(d, cookies.access_token).then((res: any) => {
            if (isMounted) {
                setTrendData (res.data)
            }
        });

        return () => {
            isMounted = false; // Cleanup function to prevent state updates after unmount
        };
    }, [device?.device_id, intervention_id, cookies.access_token]);

    //
    // Retrieve the last recorded parameter from back-end - it has the patient info in it.
    //
    const { data: parm, loading: parm_loading } = useFetch({
        url: DeviceService.getLatestParameter(intervention_id),
        access_token: token,
        id: device?.device_id,
    });


    //console.log ("Available parameters:", availableParameters)
    const savedParams = JSON.parse(
        // @ts-ignore
        // localStorage.getItem("trend-" + device?.device_id)
        localStorage.getItem(type + "-" + device?.device_id)
    );

    const [selectableParameters, setSelectableParameters] = useState<string[]>(
        []
    );

    const [selectedParameters, setSelectedParameters] = useState<string[]>(
        savedParams || ["HR"]
    );

    // Transform the trendData into series format. This only runs once when the trendData is first loaded
    useEffect(() => {
    
        if (trendData && trendData.data) {
            let parameters = trendData.device_def.parameters;

            // Only update if the parameters have changed
            if (JSON.stringify(parameters) !== JSON.stringify(trendParameters)) {
                setTrendParameters(parameters);
            }
  
            // Map trend data to chart series and update
            const { mappedSeries, minTime, maxTime } = mapTrendDataToSeries(trendData.data, selectableParameters, trendData.data.timestamps);
            if (mappedSeries) {
                setSeries(mappedSeries);
                setMinTime (minTime);
                setMaxTime (maxTime);
            }
        }
                    
    }, [trendData, selectableParameters, trendParameters]);
    


    // Update the selectable parameters based on the trendParameters
    useEffect(() => {
        //console.log ("trendParameters:", trendParameters)
        let params: string[] = [];
    
        // Collect a list of parameters we can update
        if (trendParameters) {
          Object.keys(trendParameters).forEach((key) => {
            let temp = key;
            if (temp.includes("_")) temp = temp.split("_")[0];
            else if (temp === "SPO2") temp = "SpO2";
            else if (temp === "RR") temp = "Resp";
            else if (temp === "Temp1") temp = "Temp";

            if (!params.includes(temp) && ALL_PARAMS.includes(temp)) {
                params.push(temp);
            }
          });
        }

        // Only update selectableParameters if the new parameters are different from the current ones
        if (JSON.stringify(params) !== JSON.stringify(selectableParameters)) {
            setSelectableParameters(params);
        }
    
        if (!savedParams) {
            setSelectedParameters(() => {
                if (params.length > 6) {
                    return params.filter((_param, index) => index < 6);
                } else {
                    return params;
                }
            });
        }
        
    }, [trendParameters, selectableParameters, savedParams]);


// Update trendData with new dataPacket values
useEffect(() => {
    if (dataPacket && trendData) {
        //console.log("Trend dataPacket:", dataPacket);
        if (Object.keys(dataPacket).length === 0) return;
        
        // Define new timestamp from the dataPacket
        const newTimestamp = moment(dataPacket.timestamp).unix();

        // Create new series data based on the dataPacket values
        const newSeries = selectableParameters.map((param) => {
            const paramValue = dataPacket[param as keyof typeof dataPacket];
            const existingSeries = series.find((s) => s.name === param);

            // If this parameter already has data, we don't want to append it again.
            // We will just add the new data point instead of concatenating with existing data.
            const updatedData: { x: Date; y: number | null }[] = existingSeries
                ? [...(existingSeries.data as { x: Date; y: number | null }[])]
                : [];

            // Only add the new data point if it's not a duplicate (e.g., same timestamp)
            if (
                updatedData.length === 0 ||
                updatedData[updatedData.length - 1]?.x.getTime() !== new Date(newTimestamp * 1000).getTime()
            ) {
                updatedData.push({
                    x: new Date(newTimestamp * 1000), // Convert to milliseconds
                    y: paramValue === undefined || paramValue === '-' || paramValue === '0'
                        ? null
                        : parseFloat(paramValue.toString()), // Handle missing values and undefined values
                });
                setMaxTime (newTimestamp * 1000)
            }

            return {
                name: param,
                data: updatedData,
            };
        });

        // Update the series state with the new data points
        setSeries((prevSeries) => {
            return newSeries.map((newData) => {
                const existingSeries = prevSeries.find((s) => s.name === newData.name);
                return {
                    ...newData,
                    data: existingSeries ? newData.data : newData.data, // No need to concatenate
                };
            }) as ApexAxisChartSeries;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dataPacket, selectableParameters]);


   
    
    // Update displaySeries whenever selectedParameters or series changes
    useEffect(() => {
        if (series.length === 0) {
            setDisplaySeries([]);
        }
    
        // Sort selectedParameters to ensure consistent color order
        const sortedSelectedParameters = [...selectedParameters].sort();
    
        // Filter the full series and only include those that are in the sortedSelectedParameters
        const filteredSeries = series
            .filter((s) => s.name && sortedSelectedParameters.includes(s.name))
            .sort((a, b) => sortedSelectedParameters.indexOf(a.name!) - sortedSelectedParameters.indexOf(b.name!)); // Sort series by parameter order
    
        // Map the colors for the sorted selected parameters based on vitalsColorMap
        const colors = sortedSelectedParameters.map(
            (param) => vitalsColorMap[param as keyof typeof vitalsColorMap] || "#FFFFFF"
        );
        //console.log ("Colors are :", colors)
        setDisplaySeries(filteredSeries);
        setDisplayColors(colors);
        //console.log ("displaySeries:", filteredSeries)
    }, [series, selectedParameters]);
    
        
    
    

    const theme = useTheme();

    if (parm_loading || parm === null)
        return <Loading />;


    //console.log ("trendData:", trendData)


    const trendChartOptions: ApexOptions = {
        stroke: {
            width: 2,
            curve: "smooth",
        },
        chart: {
            background: theme.palette.common.black,
            foreColor: theme.palette.common.white,
            id: device.device_id + "-chartArea",
            animations: {
                enabled: false,
            },
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true,
                },
                autoSelected: 'zoom',
            },
            zoom: {
                enabled: true,
            },
        },
        xaxis: {
            type: "datetime",
            labels: {
                formatter: (value) => moment(Number(value)).local().format("HH:mm"),
                datetimeUTC: false, // Use local timezone
            },
            tooltip: {
                enabled: true,
                formatter: (value) => moment(Number(value)).local().format("HH:mm"),
            },
        },
            yaxis: {
            labels: {
                formatter: (val: number) => val?.toFixed(0),
            },
        },
        colors: displayColors,
        markers: {
            size: 1,
            shape: "circle",
            hover: {
                size: 4,
            },
        },
        legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            labels: {
                colors: "#FFFFFF",
                useSeriesColors: false,
            },
            markers: {
                size: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 5,
            },
        },
        grid: {
            show: true,
            borderColor: "#555555", // Light grey for horizontal and vertical grid lines
            strokeDashArray: 0, // Solid lines
        },
    };
    
    const navChartOptions: ApexOptions = {
        chart: {
            width: "100%",
            background: theme.palette.common.black,
            foreColor: theme.palette.common.white,
            animations: {
                enabled: false,
            },
            id: device.device_id + "-chartBrush",
            brush: {
                target: device.device_id + "-chartArea",
                enabled: true,
            },
            selection: {
                fill: {
                    color: theme.palette.primary.main,
                    opacity: 0.5,
                },
                enabled: true,
                xaxis: {
                    min: Math.max (minTime, maxTime - 30 * 60 * 1000), // Show the last 30 minutes by default
                    max: maxTime,
                },
            },
        },
        markers: {
            size: 0,
        },
        fill: {
            type: "gradient",
            gradient: {
                opacityFrom: 0.91,
                opacityTo: 0.1,
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            type: "datetime",
            labels: {
                formatter: (value) => moment(Number(value)).local().format("HH:mm"),
                datetimeUTC: false, // Use local timezone
            },
            tooltip: {
                enabled: false,
            },
        },
            yaxis: {
            tickAmount: 2,
        },
        legend: {
            show: false,
        },
        grid: {
            show: false,
        },
        
    };
    

    
    if (hideTrend) return null;


    return (
        <Grid item md={fullWidth ?? 6}>
            <Box>
                <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: "0px", padding: "3px" }}
                >
                    {/* The blue header line with gear icon */}
                    <Grid
                        id="liveUnitHeader"
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center" // Vertically align items in the center
                        className="monitorHeader"
                        px={1}
                        pt={1}
                    >
                    {/* Left-aligned title */}
                    <Grid item>
                        <Typography
                            color={theme.palette.common.white}
                            textAlign="left" // Left-align text
                            ml={1}
                        >
                            {t("trend_chart")}
                        </Typography>
                    </Grid>

                    {/* Right-aligned SettingsIcon */}
                    <Grid item>
                        <SettingsIcon
                            style={{
                                cursor: "pointer",
                                color: "white",
                                fontSize: 22,
                            }}
                            onClick={handleClickOpen}
                        />
                    </Grid>

                    {/* Waveform Selector Dialog*/}
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle> {t("select_waveforms")} </DialogTitle>
                        <DialogContent>
                            
                        <SelectParameters
                                deviceId={device.device_id}
                                selectableParameters={selectableParameters}
                                selectedParameters={selectedParameters}
                                setSelectedParameters={setSelectedParameters}
                                maxParameters={40}
                                type={type}
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}> {t("close")}</Button>
                        </DialogActions>
                    </Dialog>

                    </Grid>

                    <Grid container height="100%" width="100%">
                        <Grid
                            item
                            container
                            xs={12}
                            height="100%"
                            width="400"
                        >
                            <TrendChart
                                height="100%"
                                options={trendChartOptions}
                                series={displaySeries.length > 0 ? displaySeries : [{ name: 'No Data', data: [] }]}  // Fallback to an empty series

                            />
                        </Grid>

                        {/* Navigation (Brush) Chart */}
                        <Grid item container xs={12} height="100%" width="400" sx={{ marginTop: "0px" }}>
                            <TrendChart
                                height="100px" // Shorter height for the navigation chart
                                options={navChartOptions}
                                type="area"
                                series={displaySeries.length > 0 ? displaySeries : [{ name: 'No Data', data: [] }]}  // Use the same series for both charts
                            />
                        </Grid>


                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};

// Wrap the component with React.memo to avoid unnecessary re-renders
export default React.memo(TrendUnit);
//export default TrendUnit;
