import { useContext, useEffect } from "react";
import * as CONSTANTS from "../utils/constants/constants";
import { UserContext } from "../context/UserContext";

interface Props {
  setAdminDataPacket: (data: any) => void;
}

const useAdminSocket = ({ setAdminDataPacket }: Props) => {
  const userCtx = useContext(UserContext);

  useEffect(() => {
    if (!userCtx?.user?.organization) return;

    // Optionally, delay connection by 100ms to allow context to settle
    const timeoutId = setTimeout(() => {
      const socket_address =
        CONSTANTS.WS_HOST() +
        "ws/homepage-socket/" +
        userCtx.user?.organization +
        "/";
      console.log("Requesting websocket at", socket_address);
      const socket = new WebSocket(socket_address);

      socket.onerror = (event) => {
        console.error("WebSocket encountered an error:", event);
      };

      socket.onclose = (event) => {
        console.warn(`WebSocket closed (code: ${event.code}, reason: ${event.reason})`);
      };

      socket.onmessage = (event) => {
        try {
          if (event.data !== "") {
            const eventData = JSON.parse(event.data);
            setAdminDataPacket(eventData);
          }
        } catch (err: any) {
          console.error(err);
        }
      };

      socket.onopen = () => {
        console.log("WebSocket connection established");
        const streamRequest = {
          type: "admin-request",
          opcode: "get-status",
          organization: userCtx.user?.organization,
        };
        socket.send(JSON.stringify(streamRequest));
      };

      // Cleanup: close the socket on unmount
      return () => {
        console.log("Closing socket");
        socket.close();
      };
    }, 100); // 100ms delay

    // Cleanup for the timeout in case the effect is cleaned up before timeout fires
    return () => clearTimeout(timeoutId);
  }, [userCtx?.user?.organization, setAdminDataPacket]);
};

export default useAdminSocket;
