import { useState, useEffect } from "react";
import { useTheme, Grid, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";
import UserService from "../../services/UserService";
import { useParams } from "react-router-dom";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from "react-router-dom";

export const ResetPassword = () => {

    const { t } = useTranslation();
    const theme = useTheme();
    const [passValue, setPassValue] = useState ("")
    const [pass2Value, setPass2Value] = useState ("")
    const [passMatch, setPassMatch] = useState (true)
    const params    = useParams();
    const [errorMsg, setErrorMsg] = useState <string []> ([])
    const [showPassError, setShowPassError] = useState (false)
    const [changeOK, setChangeOK] = useState (false);
    const history = useNavigate();

    // @ts-ignore
    const token:string = params["token"];

    const checkPasswords = (p1: string, p2: string) => {
        setPassMatch (p1 === p2)
      }

    useEffect (() => {
        checkPasswords (passValue, pass2Value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passValue, pass2Value])

    useEffect (() => {
        if (errorMsg.length === 0) {
            setShowPassError (false);
            return;
        }
        console.log ("errorMsg is ", errorMsg)
        setShowPassError (true);
    }, [errorMsg])

    const handleCloseSave = () => {
        console.log ("Close dialog handler")

        setErrorMsg ([])
        setShowPassError (false);
        UserService.confirmResetPassword (passValue, token).then ((res:any) => {
            // console.log ("confirmResetPassword result:", res)
            if (res?.status === 200) {
                setChangeOK (true);
                return;
            }

            switch (res.response.status) {
                case 400: {
                    const errors = JSON.parse (res.request.response)
                    setErrorMsg (errors.password)
                    break;
                }
                case 404: {
                    setErrorMsg (["Not found."]);
                    break;
                }
            }
        }).catch((error: any) => {

            console.log ("Error confirmResetPassword:", error)

//            setErrorMsg (error)

        }).finally(() => {
            console.log ("Completed confirmResetPassword")
        })

    };

    const handlePassErrorClose = () => {
        setShowPassError (false)
    }
    const handlePassOKClose = () => {
        setChangeOK (false);
        history("/login")
    }



    return (
        <Grid container>
            <Dialog open = {showPassError}>
                <DialogTitle>{ t ("password_change_error") }</DialogTitle>
                <DialogContent>
                <Grid item>
                    { errorMsg.map ((msg) => (
                        <Typography key={msg} color={theme.palette.error.main} > 
                            { t (msg) }
                        </Typography>
                    ))}
                </Grid>

                </DialogContent>
                <Button onClick={handlePassErrorClose}>{ t ("ok") }</Button>
            </Dialog>

            <Dialog open = {changeOK}>
                <DialogTitle>{ t ("success") } </DialogTitle>
                <DialogContent>
                <Grid item>
                        <Typography color={theme.palette.error.main} > 
                            { t ("password_change_successful") }
                        </Typography>
                </Grid>

                </DialogContent>
                <Button onClick={handlePassOKClose}>{ t ("ok") }</Button>
            </Dialog>

            <Grid item xs = {3}>
                <TextField
                    id="new_password"

                    label= { t ("new_password") }
                    type="password"
                    fullWidth
                    variant="outlined"
                    color = "primary"
                    onChange= {(e) => { setPassValue (e.target.value) }}
                    sx= {{ 
                        input: {
                            color: theme.palette.text.primary,
                            background: theme.palette.background.default
                        },
                        label: {
                            color: theme.palette.text.primary
                        }
                    }}
                />

                <TextField
                    margin="dense"
                    id="new_password2"
                    onChange= {(e) => { setPass2Value (e.target.value) }}
                    label= { t ("repeat_password")}
                    type="password"
                    fullWidth
                    variant="outlined"
                    sx= {{ 
                        input: {
                            color: theme.palette.text.primary,
                            background: theme.palette.background.default
                        },
                        label: {
                            color: theme.palette.text.primary
                        }
                    }}                
                />

                { !passMatch && <Typography
                    color={theme.palette.error.main}
                    > 
                    { t ("passwords_dont_match") }
                    </Typography>
                }


                <Grid item  xs={2}>
                    <Button onClick={handleCloseSave}>{ t ("save") }</Button>
                </Grid>

            </Grid>
        </Grid>

    );




} // ResetPassword
