import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    useTheme,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
    List,
    ListItem,
    ListItemText,
    InputBase,
    Paper,
    
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { OrganizationHierarchyNode, PoolDevice } from './types'; // Define your types in a separate file or within components
import { DeviceType } from "../../pages/SystemManagement/types"
import CustomSelect from "../../components/CustomSelect"
import DeviceService from "../../services/DeviceService"
import MessageAlert from "../../components/MessageAlert";
import UploadIcon from '@mui/icons-material/Upload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Import the copy icon
import GenericSwitch from './GenericSwitch'; // Adjust the import path as necessary

export const validateSerialNumber = (serial: string): boolean => {
    // Regular expression for URL-safe characters
    const urlSafeRegex = /^[a-zA-Z0-9-_]+$/;
    return urlSafeRegex.test(serial);
};


const handleCopyToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy)
        .then(() => {
            //alert('Invalid serial numbers copied to clipboard.');
        })
        .catch(err => {
            console.error('Failed to copy text to clipboard: ', err);
        });
};

interface Props {
    initialDevice?: PoolDevice;
    openState: boolean;
    setOpenState: (show: boolean) => void;
    dialogMode: string;
    parentNode: OrganizationHierarchyNode | null;
    onDeviceAdded: (devices: string[]) => void;
    deviceTypes: DeviceType[];
    maxAvailable: number;
}

const PoolDeviceDialog = ({
    initialDevice,
    openState,
    setOpenState,
    dialogMode,
    parentNode,
    deviceTypes,
    onDeviceAdded,
    maxAvailable
}: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [cookies, ,] = useCookies(["access_token"]);
    const [selectedDeviceType, setSelectedDeviceType] = useState<DeviceType>();
    const [deviceId, setDeviceId] = useState<string>('');
    const [serialNumber, setSerialNumber] = useState<string>('');
    //const [serialnumberChanged, setSerialNumberChanged] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [openMessageDialog, setOpenMessageDialog] = useState(false);
    const [message, setMessage] = useState<string[]>([t("alert"), '']);
    const [uploadMode, setUploadMode] = useState(true); // Default to "Upload from File"
    const [fileContent, setFileContent] = useState<string[]>([]);
    const [filterText, setFilterText] = useState('');
    const [invalidSerialNumbers, setInvalidSerialNumbers] = useState<string[]>([]);
    const [unableToAddDevices, setUnableToAddDevices] = useState<string[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    //console.log ("PoolDeviceDialog:", maxAvailable, parentNode)
    const toggleDeviceInfo = () => {
        setUploadMode(!uploadMode);
    };


    const handleItemChange = (id: string, data: any) => {
        switch (id) {
            case "serialNumber": {
                const value: string = String(data).trim();
                if (validateSerialNumber(value) || value === '') {
                    //setSerialNumberChanged(value !== initialDevice?.device_serialnumber);
                    setSerialNumber(value);
                    setDeviceId(value);
                }
                break;
            }
        }
    }

    const handleDeviceTypeChanged = (dtype: string) => {
        const deviceEntry = sortedDeviceTypes.find(item => item.model === dtype);
        if (deviceEntry) {
            setSelectedDeviceType(deviceEntry);
        } else {
            console.error('Device Type not found for :', dtype);
        }
    };

    useEffect(() => {
        if (!initialDevice) {
            return;
        }
        function findDeviceTypeById(model: string): DeviceType | undefined {
            return parentNode?.devicetypes.find(dtype => dtype.model === model);
        }

        setDeviceId(initialDevice.device_id);
        setSerialNumber(initialDevice.device_serialnumber);
        const devtype = findDeviceTypeById(initialDevice.deviceType);
        setSelectedDeviceType(devtype);
    }, [initialDevice, parentNode?.devicetypes]);

    useEffect(() => {
        var showSave = false;
        switch (dialogMode) {
            case "edit": {
                showSave = false;
                break;
            }
            case "add": {
                showSave = serialNumber !== '' && selectedDeviceType !== undefined;
                break;
            }
        }
        setShowSaveButton(showSave);
    }, [dialogMode, serialNumber, selectedDeviceType]);


    const cancelDelete = () => {
        setConfirmDelete(false);
    };

    const yesDelete = () => {
        DeviceService.deletePoolDevice(deviceId, cookies.access_token)
            .then((res: any) => {
                //onDeviceAdded();
            })
            .catch((err: any) => {
                console.log(err);
            }).finally(() => {
                setConfirmDelete(false);
                handleClose();
            });
    };

    const handleClose = () => {
        setOpenState(false);
        setSerialNumber('');
        //setSerialNumberChanged(false);
        setSelectedDeviceType(undefined);
        setUploadMode(true); // Reset to default "Upload from File"
        setFileContent([]);
    }

    const handleSaveChanges = () => {
        if (dialogMode === "add") {
            const data = {
                "device_id": deviceId,
                "device_model": selectedDeviceType?.model || '',
                "serial_number": serialNumber,
                "parentNode": parentNode?.node_name || '',
            }
            DeviceService.addPoolDevice(data, cookies.access_token)
                .then((res: any) => {
                    if (res.status === 409) {
                        console.log ("Unable to add device:", res)
                        setUnableToAddDevices([]);
                        setMessage(["Unable to add device", "Device " + deviceId + " already exists"]);
                        setOpenMessageDialog(true);
                    } else if (res.status === 200) {
                        setShowSaveButton(false);
                        onDeviceAdded([deviceId]);
                        handleClose();
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                }).finally(() => {
                });

            handleClose();
            return;
        }
    }

    const handleCloseMessageDialog = () => setOpenMessageDialog(false);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                if (e.target && typeof e.target.result === 'string') {
                    const content = e.target.result.split('\n').map(line => line.trim()).filter(line => line);
                    const invalidSerials = content.filter(serial => !validateSerialNumber(serial));
                    if (invalidSerials.length > 0) {
                        setInvalidSerialNumbers(invalidSerials);
                        setMessage([t("alert"), t("found_invalid_serialnumbers")])
                        setOpenMessageDialog(true);
                        // Reset file input value to null to allow re-upload of the same file
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        return;
                    }

                    if (content.length > maxAvailable) {
                        setMessage ([
                            t("alert"), 
                            t ("not_enough_licenses"),
                            t("max_available") + " : " + maxAvailable,
                            t("entries_in_file") + " : " + content.length])
                        setOpenMessageDialog (true);
                        // Reset file input value to null to allow re-upload of the same file
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        return;
                    }
                    setFileContent(content);
                    console.log ("handleFileUpload content is ", content)
                    setUploadMode(true); // Set uploadMode to true after reading the file content
                    // Reset file input value to null to allow re-upload of the same file
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                }
            };
            reader.onerror = (e) => {
                console.error("Error reading file:", e);
            };
            reader.readAsText(file);
        }
    };

    const handleAcceptUpload = () => {
        const data = {
            "device_list": fileContent,
            "device_model": selectedDeviceType?.model || '',
            "parentNode": parentNode?.node_name || '',
        }
        console.log ("handleAcceptUpload:", data)
        console.log ("parentNode:", parentNode)
        console.log ("fileContent:", fileContent)
        const tmpDeviceList = fileContent;
        DeviceService.addPoolDevice(data, cookies.access_token)
            .then((res: any) => {
                if (res.status === 409) {
                    setUnableToAddDevices(res.data.device_list);
                    setMessage(["Unable to add device", "Device already exists"]);
                    setOpenMessageDialog(true);
                } else if (res.status === 200) {
                    console.log ("Added devices successful:", res)
                    setShowSaveButton(false);
                    console.log ("calling onDeviceAdded with ", tmpDeviceList)
                    onDeviceAdded(tmpDeviceList);
                    handleClose();
                } else if (res.status === 413) {
                    console.log (res)
                    //setUnableToAddDevices(["Available licenses: " + res.data.available_licenses]);
                    setMessage(["Unable to add devices", "Not enough free licenses"]);
                    setOpenMessageDialog(true);
                }
            })
            .catch((err: any) => {
                console.log(err);
            }).finally(() => {
            });

        handleClose();
    };

    const handleCancelUpload = () => {
        setUploadMode(false);
        setFileContent([]);
        handleClose();
    };



    const filteredContent = fileContent.filter(serial =>
        serial.toLowerCase().includes(filterText.toLowerCase())
    );

    const sortedDeviceTypes = [...deviceTypes].sort((a, b) =>
        a.model.localeCompare(b.model, undefined, { sensitivity: 'base' })
    );

    return (
        <>
            <Dialog open={confirmDelete}>
                <DialogTitle> {t("confirm_organization_delete")} </DialogTitle>
                <DialogContent>
                    <Typography>{t("delete_organization_notification")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Button variant="outlined" onClick={cancelDelete}> {t("cancel")}</Button>
                        <Button variant="contained" onClick={yesDelete} color="warning">  {t("yes_delete")}</Button>
                    </Grid>
                </DialogActions>
            </Dialog>

            <MessageAlert
                open={openMessageDialog}
                message={message}
                onClose={handleCloseMessageDialog}
            />

            <Dialog
                open={openState}
                fullWidth
                maxWidth="sm" // Set the dialog width to be narrower
                onClose={handleClose} // This will handle the escape key as well
            >
                <DialogTitle>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs>
                            <Box>{t("add_pool_device")}</Box>
                        </Grid>
                        <Grid item sx={{ minWidth: 150 }}>
                            <CustomSelect
                                label="Device Type"
                                options={sortedDeviceTypes}
                                valueExtractor={(role) => role.model}
                                descriptionExtractor={(role) => role.model}
                                value={selectedDeviceType?.model || ""}
                                onChange={handleDeviceTypeChanged}
                                disabled={dialogMode === "edit"}
                            />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{ mb: 2 }}>
                        <Grid item>
                            <GenericSwitch
                                isActive={!uploadMode}
                                toggleSwitch={toggleDeviceInfo}
                                leftLabel={t("upload_from_file")}
                                rightLabel={t("register_single_device")}
                                leftAction={() => setUploadMode(true)}
                                rightAction={() => setUploadMode(false)}
                            />

                        </Grid>
                        
                    </Grid>
                    <Grid container item pt={3} mb={1} spacing={2}>
                        <Grid item xs={12}>
                            {uploadMode ? (
                                <div>
                                    {!fileContent.length && (
                                        <Button
                                            variant="outlined"
                                            component="label"
                                            startIcon={<UploadIcon />}
                                            sx={{ mt: 1 }}
                                        >
                                            Upload from File
                                            <input
                                                type="file"
                                                accept=".csv,.txt"
                                                hidden
                                                onChange={handleFileUpload}
                                                ref={fileInputRef} // Attach the ref to the input element
                                            />
                                        </Button>
                                    )}
                                    {fileContent.length > 0 && (
                                        <>
                                            <Paper
                                                component="form"
                                                sx={{ mt: 2, mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center' }}
                                            >
                                                <InputBase
                                                    sx={{ ml: 1, flex: 1 }}
                                                    placeholder="Filter serial numbers"
                                                    inputProps={{ 'aria-label': 'filter serial numbers' }}
                                                    value={filterText}
                                                    onChange={(e) => setFilterText(e.target.value)}
                                                />
                                            </Paper>
                                            <List sx={{ maxHeight: 200, overflow: 'auto', border: '1px solid' }}>
                                                {filteredContent.map((serial, index) => (
                                                    <ListItem key={index}>
                                                        <ListItemText primary={serial} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                            <Typography variant="body2" sx={{ mt: 1 }}>
                                                Total devices: {fileContent.length}
                                            </Typography>
                                            <Button
                                                variant="outlined"
                                                onClick={handleAcceptUpload}
                                                sx={{ mt: 1 }}
                                                disabled={!selectedDeviceType} // Enable only if a device type is selected
                                            >
                                                Accept Upload
                                            </Button>
                                            <Button variant="outlined" onClick={handleCancelUpload} sx={{ mt: 1, ml: 1 }}>
                                                Cancel
                                            </Button>
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <TextField
                                        label="Serial Number"
                                        id="serialnumber"
                                        type=""
                                        disabled={dialogMode === "edit"}
                                        value={serialNumber}
                                        onChange={(e) => handleItemChange("serialNumber", e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="Enter Serial Number"
                                        sx={{
                                            input: {
                                                color: theme.palette.text.primary,
                                                background: theme.palette.background.default
                                            },
                                            label: { color: theme.palette.text.primary },
                                        }}
                                        fullWidth
                                    />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Grid item>
                        <Button variant="outlined" onClick={handleClose}>
                            {t("close")}
                        </Button>
                        </Grid>
                        {showSaveButton && !uploadMode && (
                        <Grid item>
                            <Button onClick={handleSaveChanges} variant="outlined">
                            {t("save_changes")}
                            </Button>
                        </Grid>
                        )}
                    </Grid>
                </DialogActions>
            </Dialog>

            {/* Dialog to show invalid serial numbers */}
            <Dialog
                open={invalidSerialNumbers.length > 0}
                onClose={() => setInvalidSerialNumbers([])}
                fullWidth
                maxWidth="sm"
                >
                <DialogTitle>{t("invalid_serialnumbers")}</DialogTitle>
                <DialogContent>
                    <List sx={{ maxHeight: 200, overflow: 'auto', border: '1px solid' }}>
                        {invalidSerialNumbers.map((serial, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={serial} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="flex-start">
                        <Button
                            variant="outlined"
                            startIcon={<ContentCopyIcon />}
                            onClick={() => handleCopyToClipboard(invalidSerialNumbers.join('\n'))}
                        >
                            Copy to Clipboard
                        </Button>
                    </Grid>
                    <Button onClick={() => setInvalidSerialNumbers([])}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog to show unable to add devices */}
            <Dialog
                open={unableToAddDevices.length > 0}
                onClose={() => setUnableToAddDevices([])}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>{t("unable_to_add_devices")}</DialogTitle>
                <DialogContent>
                    <List sx={{ maxHeight: 200, overflow: 'auto', border: '1px solid' }}>
                        {unableToAddDevices.map((device, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={device} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="flex-start">
                        <Button
                            variant="outlined"
                            startIcon={<ContentCopyIcon />}
                            onClick={() => handleCopyToClipboard(unableToAddDevices.join('\n'))}
                        >
                            Copy to Clipboard
                        </Button>
                    </Grid>
                    <Button onClick={() => setUnableToAddDevices([])}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default PoolDeviceDialog;
