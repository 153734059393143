import { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";

export type ProtectedRouteProps = {
  component: React.ElementType;
  authenticationPath?: string;
};

export default function ProtectedRoute({
  component: Component,
  authenticationPath = "/login",
}: ProtectedRouteProps) {
  const userCtx = useContext(UserContext);
  const [cookies, , removeCookies] = useCookies(["access_token", "refresh_token"]);

  useEffect(() => {
    if (!cookies.access_token) {
      localStorage.removeItem("user");
      removeCookies("access_token");
      removeCookies("refresh_token");
    }
  }, [cookies, removeCookies]);

  return cookies.access_token ? (
    <UserContext.Provider value={userCtx}>
      <Component />
    </UserContext.Provider>
  ) : (
    <Navigate to={authenticationPath} replace />
  );
}
