import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AutoLogoutProvider } from "./components/AutoLogoutProvider";
import Login from "./pages/Login/Login";

import "./index.css";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserProvider } from "./context/UserContext";
import ThemeContextProvider from "./context/ThemeContext";
import { CookiesProvider } from "react-cookie";
import { CMSWrapper } from "./components/CMS/CMSWrapper";
import ParametersProvider from "./context/ParametersContext";
import DevicesProvider from "./context/DevicesContext";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { PageNotFound } from "./pages/ReRoute/PageNotFound";
import AlarmsProvider from "./context/AlarmsContext";
import { ToastContainer } from "react-toastify";
import { MainScreen } from "./components/CMS/MainScreen";
import { Profile } from "./pages/Profile/Profile";
import { ForgotPassword } from "./pages/Login/ForgotPassword";
import { UserManagement } from "./pages/UserMgmt/UserManagement";
import { AccessManagement } from "./pages/AccessManagement/AccessManagement";
import { DeviceManagement } from "./pages/DeviceManagement/DeviceManagement";
import { ServerManagement } from "./pages/SystemManagement/ServerManagement";
import { ResetPassword } from "./pages/Login/ResetPassword";
import DeviceSocketProvider from "./context/DeviceSocketContext";
import { WaveMonitor } from "./pages/WaveMonitor";
import { CareSummary } from "./pages/CareSummary";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CacheBuster from "react-cache-buster";
import { SysAdmin } from "./pages/SysAdmin/SysAdmin";
import MonitorGroupManager from "./pages/MonitorGroups/MonitorGroupManager";

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "de", "fr", "no", "ar", "ro", "it", "es", "ca"],
    fallbackLng: "en",
    debug: false,
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const App = () => {
  const isProduction = process.env.NODE_ENV === "production";
  const buildNumber = process.env.REACT_APP_BUILD_NUMBER || "0.0.0";
  console.log("isProduction:", isProduction);

  return (
    <CacheBuster
      currentVersion={buildNumber}
      isEnabled={true} // Enable cache busting
      isVerboseMode={false}
      metaFileDirectory="."
    >
      <DndProvider backend={HTML5Backend}>
        <CookiesProvider>
          <ThemeContextProvider>
            <UserProvider>
              <AutoLogoutProvider>
                <DevicesProvider>
                  <ParametersProvider>
                    <AlarmsProvider>
                      <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        draggable
                        pauseOnHover
                        pauseOnFocusLoss={false}
                      />
                      <DeviceSocketProvider>
                        <Router basename={process.env.PUBLIC_URL + "/"}>
                          <CMSWrapper>
                            <Routes>
                              <Route path="/login" element={<Login />} />
                              <Route
                                path="/profile"
                                element={<ProtectedRoute component={Profile} />}
                              />
                              <Route
                                path="/wavemonitor/:deviceId"
                                element={<ProtectedRoute component={WaveMonitor} />}
                              />
                              <Route
                                path="/caresummary/:deviceId"
                                element={<ProtectedRoute component={CareSummary} />}
                              />
                              <Route
                                path="/usermgmt"
                                element={<ProtectedRoute component={UserManagement} />}
                              />
                              <Route
                                path="/devicemgmt"
                                element={<ProtectedRoute component={DeviceManagement} />}
                              />
                              <Route
                                path="/accessmgmt"
                                element={<ProtectedRoute component={AccessManagement} />}
                              />
                              <Route
                                path="/vicumgmt"
                                element={<ProtectedRoute component={MonitorGroupManager} />}
                              />
                              <Route
                                path="/sysmgmt"
                                element={<ProtectedRoute component={ServerManagement} />}
                              />
                              <Route
                                path="/sysadmin"
                                element={<ProtectedRoute component={SysAdmin} />}
                              />
                              <Route
                                path="/forgot-password"
                                element={<ForgotPassword />}
                              />
                              <Route
                                path="/password-reset/:token"
                                element={<ResetPassword />}
                              />
                              <Route
                                path="/"
                                element={<ProtectedRoute component={MainScreen} />}
                              />
                              <Route path="*" element={<PageNotFound />} />
                            </Routes>
                          </CMSWrapper>
                        </Router>
                      </DeviceSocketProvider>
                    </AlarmsProvider>
                  </ParametersProvider>
                </DevicesProvider>
              </AutoLogoutProvider>
            </UserProvider>
          </ThemeContextProvider>
        </CookiesProvider>
      </DndProvider>
    </CacheBuster>
  );
};

const loadingMarkup = (
  <div>
    <h3>Loading..</h3>
  </div>
);

const container = document.getElementById("root");
if (!container) {
  throw new Error("Root container not found");
}
const root = createRoot(container);
root.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
);

serviceWorkerRegistration.register();
