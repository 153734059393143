import { ReactNode, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import { UserContext } from "../../context/UserContext";
import { Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useCookies } from "react-cookie";
import { ThemeContext } from "../../context/ThemeContext";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import "./CMSWrapper.css";
import Navbar from "./CMSNavbar";
import SysAdminNavbar from "../../pages/SysAdmin/SysAdminNavBar";
import LightModeLogo from "../../assets/images/infiniwell_light_logo.png";
import DarkModeLogo from "../../assets/images/infiniwell_PMS_logo-symbol.png"; // Import the dark mode logo

interface Props {
    children: ReactNode;
}

export const CMSWrapper = ({ children }: Props) => {
    const userCtx = useContext(UserContext);
    const [cookies] = useCookies(["access_token", "refresh_token"]);
    const themeCtx = useContext(ThemeContext);
    const history = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user") || "null");
    
        if (user && typeof user.role === "string") {
            localStorage.removeItem("user");
            window.location.href = "/login";
            return;
        }
    
        // Only update the user context if it has genuinely changed
        if (cookies.access_token && user && (!userCtx?.user || userCtx.user.user_id !== user.user_id)) {
            userCtx?.setUser(user);
        }
    }, [cookies.access_token, userCtx]);


    const colorMode = () => {
        themeCtx.toggleColorMode();
        const themeTo = theme.palette.mode === "light" ? "dark" : "light";
        localStorage.setItem("mode", themeTo);
    };

    const logoSrc = theme.palette.mode === "dark" ? DarkModeLogo : LightModeLogo;
    console.log ("Userctx:", userCtx?.user)
    if (userCtx?.user) {
        return (
            <Grid
                sx={{
                    height: "max-content",
                    minHeight: "100vh",
                    bgcolor: theme.palette.background.default,
                }}
            >
                {!userCtx?.user.is_superuser && <Navbar />}
                {userCtx?.user.is_superuser && <SysAdminNavbar />}

                <Grid
                    sx={{
                        margin: 0,
                        bgcolor: theme.palette.background.default,
                    }}
                >
                    {children}
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid
                sx={{
                    height: "100vh",
                    bgcolor: "theme.palette.background.default",
                }}
            >
                <AppBar
                    position="sticky"
                    sx={{
                        bgcolor: theme.palette.background.default,
                        paddingBottom: "1rem",
                    }}
                >
                    <div className="header">
                        <img
                            className="logo"
                            alt="Infiniwell-logo"
                            src={logoSrc}
                            width="55"
                            height="30"
                            onClick={() => history("/")}
                        />
                        <Typography
                            color={theme.palette.text.primary}
                            variant="h5"
                            sx={{ textAlign: "center" }}
                        >
                            Hospital has left the building
                        </Typography>
                        <IconButton
                            sx={{ ml: 1 }}
                            onClick={colorMode}
                            color="primary"
                        >
                            {theme.palette.mode === "dark" ? (
                                <Brightness7Icon />
                            ) : (
                                <Brightness4Icon />
                            )}
                        </IconButton>
                    </div>
                </AppBar>
                <Grid
                    sx={{
                        padding: "2rem",
                        bgcolor: theme.palette.background.default,
                    }}
                >
                    {children}
                </Grid>
            </Grid>
        );
    }
};
