import { useContext, useEffect, useState, useRef } from "react";
import { Device } from "../interfaces/Device";
import { PackageVitals } from "../utils/functions/PackageVitals";
import * as CONSTANTS from "../utils/constants/constants";
import { UserContext } from "../context/UserContext";

interface Props {
  device: Device;
  setDataPacket: (data: any) => void;
  mode: string;
}

const useSentioSocket = ({ device, setDataPacket, mode = "stream_parameters" }: Props) => {
  const userCtx = useContext(UserContext);
  const last_datagram_ts = useRef(0);
  const [connectStatus, setConnectStatus] = useState("disconnected");
  const socketConnectStatus = useRef(false);
  const socketRef = useRef<WebSocket | null>(null);

  const startMonitorConnectivityCheck = (isMounted: boolean) => {
    if (isMounted) {
      setTimeout(() => checkMonitorTimeout(isMounted), 3000);
    }
  };

  const checkMonitorTimeout = (isMounted: boolean) => {
    const timer = setTimeout(() => checkMonitorTimeout(isMounted), 3000);
    const timeNow = Date.now();
    const diff = timeNow - last_datagram_ts.current;
    if (diff > CONSTANTS.CONNECTIVITY_TIMEOUT) {
      if (socketConnectStatus.current && isMounted) {
        setConnectStatus("disconnected");
        socketConnectStatus.current = false;
      }
    }
    if (!isMounted) {
      clearTimeout(timer);
    }
  };

  const strobeConnectivity = (isMounted: boolean) => {
    if (!socketConnectStatus.current && isMounted) {
      setConnectStatus("connected");
      socketConnectStatus.current = true;
    }
    last_datagram_ts.current = Date.now();
  };

  useEffect(() => {
    let isMounted = true;
    // Only establish socket if organization is available
    if (!userCtx?.user?.organization) return;

    // Delay connection slightly to let context settle
    const delay = 100; // 100ms delay
    const timeoutId = setTimeout(() => {
      if (!isMounted) return;
      const socketUrl =
        CONSTANTS.WS_HOST() +
        "ws/vicu-socket/" +
        userCtx?.user?.organization +
        "/" +
        device.device_id +
        "/";
      //console.log("Requesting websocket at", socketUrl);
      const socket = new WebSocket(socketUrl);
      socketRef.current = socket;

      socket.onmessage = (event) => {
        try {
          if (event.data !== "") {
            const data = JSON.parse(event.data);
            strobeConnectivity(isMounted);
            switch (data.message.packet_type) {
              case "HEARTBEAT":
                setDataPacket(data.message);
                break;
              default: {
                const vitalsRet = PackageVitals(connectStatus, event);
                setDataPacket(vitalsRet);
                break;
              }
            }
          }
        } catch (err: any) {
          console.error(err);
        }
      };

      socket.onopen = () => {
        //console.log ("socket opened for device ", device.device_id);
        const streamRequest = {
          type: "server-request",
          opcode: mode,
          organization: userCtx.user?.organization ?? "",
          device_id: device.device_id,
        };
        socket.send(JSON.stringify(streamRequest));
        startMonitorConnectivityCheck(isMounted);
      };

      socket.onerror = (event) => {
        console.error("WebSocket error:", event);
      };

      socket.onclose = (event) => {
        //console.warn(`WebSocket closed (code: ${event.code}, reason: ${event.reason}) device: ${device.device_id}`);
      };
    }, delay);

    return () => {
      clearTimeout(timeoutId);
      isMounted = false;
      if (socketRef.current) {
        //console.log("Component unmount: closing socket for device ", device.device_id);
        socketRef.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device.device_id, userCtx?.user?.organization, mode, setDataPacket]);

};

export default useSentioSocket;
